import axios from "@/axios/axiosConfig";

export const listInstrumentSpecialPrice = (params) => {
  return axios({
    url: "/instrumentSpecialPrice/listInstrumentSpecialPrice",
    method: "post",
    data: params
  });
};

export const listH5InstrumentInventory = (params) => {
  return axios({
    url: "/instrumentInventoryManagement/listH5InstrumentInventory",
    method: "post",
    data: params
  });
};
