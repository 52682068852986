<template>
  <div class="app-box">
    <div class="data-tip">数据更新时间为{{updateTime}}。</div>
    <div class="search-box">
      <input type="text" placeholder="请输入货品名称或ID" class="search-txt" v-model="keyWord" @input="inputEvent">
    </div>
    <x-scroll-view :top="0.82" @scrollLoadData="scrollLoadData">
      <table v-if="xhrData.length>0" class="data-table double-table" style="width:8.2rem;">
        <colgroup>
          <col style="width:1.6rem;">
          <col style="width:0.7rem;">
          <col style="width:1.1rem;">
          <col style="width:1.6rem;">
          <col style="width:0.8rem;">
          <col style="width:1rem;">
          <col style="width:0.6rem;">
          <col style="width:0.8rem;">
        </colgroup>
        <thead>
          <tr>
            <th>
              <span>货品信息</span>
            </th>
            <th scope="col">
              <span>当前数量</span>
            </th>
            <th scope="col">
              <span>剩余有效期(天)</span>
            </th>
            <th scope="col">
              <span>失效日期</span>
            </th>
            <th scope="col">
              <span>终端价(元)</span>
            </th>
            <th scope="col">
              <span>经销商价(元)</span>
            </th>
            <th scope="col">
              <span>单位</span>
            </th>
            <th scope="col">
              <span>包装大小</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in xhrData" :key="index">
            <th scope="row">
              <div>{{item.goodsId}}.{{item.goodsName}}</div>
              <div>{{item.specification}}</div>
            </th>
            <td>{{item.currentCount}}</td>
            <td>{{item.expirationPeriod}}</td>
            <td>{{item.expirationDate}}</td>
            <td>{{item.terminalPrice|formatNum}}</td>
            <td>{{item.dealerPrice|formatNum}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.packingSize}}</td>
          </tr>
        </tbody>
      </table>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0"></x-no-data-bg>
    </x-scroll-view>
  </div>
</template>

<script>
import scrollView from "@/components/scrollView";
import noDataBg from "@/components/noDataBg";
import { page } from "@/script/mixins/page";
import { listH5InstrumentInventory } from "@/api/productInquiry";
import { formatNum } from "@/script/tools";

export default {
  data () {
    return {
      updateTime: null,
      timer: null,
      keyWord: null
    };
  },
  mixins: [page, formatNum],
  created () {
    document.title = "库存和一般价格";
    this.getDataList();
  },
  methods: {
    getDataList (isReset = true) {
      listH5InstrumentInventory({
        keyWord: this.keyWord,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        this.updateTime = xhr.data.updateTime;
        const listData = xhr.data.list;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
      });
    },
    inputEvent () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.pageNum = 1;
        this.ajaxEnd = false;
        this.getDataList();
      }, 300);
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "库存和一般价格";
      }
    }
  },
  components: {
    [scrollView.name]: scrollView,
    [noDataBg.name]: noDataBg
  }
};
</script>

<style scoped>
.search-txt {
  flex: 1;
  margin: auto 0.15rem;
}
</style>
